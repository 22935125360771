import { generalMixin } from "@/utils/general-mixin";
import { addGenericRecordsDetail, updateGenericRecordsDetail, getGenericRecordsDetail, archiveGenericRecordsDetail } from '@/api/utility'
import { addCategory, updateCategory } from "@/api/category";
import cloneDeep from "lodash/cloneDeep"
import { mapState } from "vuex"

/*
  This is used by (please amend accordingly)
  1. Category management
  2. Channel management

  DO NOT CHANGE ANYTHING HERE,
  you can only override anything in your own component

  Please define the following in your component data
  type: 'meter_brand',
*/
export const genericRecordsDetailMixin = {
  data() {
    return {
      isLoading: false,
      id: null,
      isAdding: false,
      isEditing: false,
      isCategory: false,

      // Edit: take and use directing from API
      // Add: get language list and add required field in
      detail: {
        translationList: [
          {
            name: '',
          }
        ],
      },
      rules: {
        detailName: [
          { validator: this.checkName, trigger: 'blur' },
        ],
      },
      onlyLanguage: null,
      forLanguage: "en",
    }
  },
  mixins: [ generalMixin ],
  watch: {
    isAdmin() {
      this.checkAccessLevelAndInit()
    }
  },
  methods: {
    handleClear() {
      this.detailName = ""
      this.detail.status = "ACTIVE"
    },
    handleArchive() {
      if (!this.fullAccess) return
      this.isLoading = true
      archiveGenericRecordsDetail(this.detail.id).then(res => {
        if (res.status == 'ok') {
          this.$notify({
            type: 'success',
            message: this.$t("ARCHIVED_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
        }
      })
      .catch(this.handleError)
      .finally(() => {
        this.isLoading = false
        this.exit()
      })
    },
    checkName(_, __, callback) {
      if (!this.detailName || this.detailName == "" || this.detailName.trim().length <= 0) {
        callback(new Error(this.$t("NAME_CANNOT_EMPTY")));
      } else if (!this.detailNameEn || this.detailNameEn == "") {
        callback(new Error(this.$t("EN_NAME_CANNOT_EMPTY")));
      } else {
        callback()
      }
    },
    handleSubmit() {
      if (!this.fullAccess) return
      this.$refs['form'].validate((valid) => {
        if (!valid) return

        if (this.isAdding) this.handleAdd()
        else this.handleEdit()
      });
    },
    handleEdit() {
      this.isLoading = true
      const api = this.isCategory ? updateCategory : updateGenericRecordsDetail;

      api(this.detail)
      .then(res => {
        if (res.status == 'ok') {
          this.$notify({
            type: 'success',
            message: this.$t("UPDATE_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
          this.exit()
        }
      })
      .catch(this.handleError)
      .finally(() => {
        this.isLoading = false
      })
    },
    handleAdd() {
      this.isLoading = true
      const api = this.isCategory ? addCategory : addGenericRecordsDetail;
      // console.log('Sending: ', this.detail)
      api(this.detail).then(res => {
        if (res.status == 'ok') {
          this.$notify({
            type: 'success',
            message: this.$t("ADDED_SUCCESSFULLY"),
            duration: this.NOTIFY_DURATION,
            position: this.NOTIFY_POSITION
          })
          this.exit()
        }
      })
      .catch(this.handleError)
      .finally(() => {
        this.isLoading = false
      })
    },
    handleError(errorMessage) {
      this.$notify({
        type: 'error',
        message: errorMessage, 
        duration: this.NOTIFY_DURATION,
        position: this.NOTIFY_POSITION
      })
    },
    // GENERAL
    getDetail() {
      // console.log('Sending: ', this.id)
      this.isLoading = true
      getGenericRecordsDetail(this.id)
      .then(res => {
        if (res.status != 'ok') return
        this.detail = cloneDeep(res.result)
      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
    initAdd() {
      this.detail.type = this.type
      this.detail.translationList = this.langList.map((el) => {
        return { 
          name: '',
          language: el
        }
      })
    },
    checkAccessLevelAndInit() {
      if (this.id) {
        this.isEditing = this.fullAccess
        this.getDetail()
      } else if (this.fullAccess) {
        this.isAdding = true
        this.initAdd()
      }
    },
  },
  computed: {
    ...mapState('app', ['langList']),

    detailName: {
      get() { 
        const locale = this.onlyLanguage || this.forLanguage
        return this.getNameFromTransList(this.detail, "", locale)
      },
      set(newValue) {
        const locale = this.onlyLanguage || this.forLanguage
        let index = this.detail.translationList.findIndex(el => el.language.code == locale)
        let obj = this.detail.translationList[index]
        if (obj == null) {
          obj = {
            name: newValue,
            language: this.langList.find(el => el.code == locale)
          }
          index = this.detail.translationList.length
        } else {
          obj.name = newValue
        }
        this.$set(this.detail.translationList, index, obj)
      }
    },
    detailNameEn: {
      get() { 
        return this.getNameFromTransList(this.detail, "", "en")
      }
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.checkAccessLevelAndInit()
    if (this.onlyLanguage != null) this.forLanguage = null
  },
}