<template>
  <div id="channel-management-detail">
    <el-form 
      ref="form" 
      :model="detail" 
      :rules="rules"
      label-position="top" 
      label-width="120px"
      :disabled="!fullAccess"
      @submit.native.prevent="handleSubmit"
    >

      <el-row v-if="!isAdding" type="flex" justify="end" align="middle" class="status-row">
        <span class="el-form-item__label text-bold">{{ $t('STATUS') }}:</span>
        <el-select 
          v-model="detail.status"
          :class="getStatusClass(detail.status) + (isMobile ? ' w-100': '')">
          <el-option
            v-for="item in allSelectableStatusListFor(detail.status)"
            :key="item.value"
            :label="$t(item.value)"
            :value="item.value"
          />
        </el-select>
      </el-row>

      <el-row :type="isMobile ? '' : 'flex'" justify="space-between" align="middle" :gutter="40">

        <el-col>
          <el-form-item class="is-required" :label="$t('CHANNEL_NAME')" prop="detailName">
            <el-input v-model="detailName">
              <template slot="prefix">
                <i class="custom-icon icon-channel mini"/>
              </template>
            </el-input>
          </el-form-item>
        </el-col>

        <el-col>
          <el-form-item class="is-required" :label="$t('CHANNEL_URL')" prop="code">
            <el-input v-model="detail.code" :placeholder="$t('SAMPLE_URL')">
              <template slot="prefix">
                <i class="custom-icon icon-link mini"/>
              </template>
            </el-input>
          </el-form-item>
        </el-col>

      </el-row>

      <page-footer
        :isLoading="isLoading"
        :handleBackTo="exit"
        :handleSubmit="fullAccess ? () => {} : null"
        :handleArchive="fullAccess ? handleArchive : null"
      />

    </el-form>

  </div>
</template>


<script>
import { genericRecordsDetailMixin } from '@/utils/_mixins/generic-records-detail'
import { generalMixin } from "@/utils/general-mixin";
import PageFooter from "@/components/page-footer.vue"

export default {
  name: 'ChannelManagementDetail',
  components: { PageFooter },
  mixins: [ genericRecordsDetailMixin, generalMixin ],
  data() {
    return {
      type: 'channel_link',
      rules: {
        detailName: [
          { validator: this.checkName, trigger: 'blur' },
        ],
        code: [
          { type: "url", message: this.$t("URL_INVALID"), trigger: "blur" },
          { validator: this.checkURL, trigger: 'blur' },
        ]
      },
      onlyLanguage: "en"
    }
  },
  methods: {
    // REDIRECTION
    exit() { 
      this.goTo('/bo/channel')
      this.$store.dispatch('app/getAllChannelLink') // to reflect the changes
    },
    checkURL(_, value, callback) {
      if (!value || value == "") {
        callback(new Error(this.$t("URL_CANNOT_EMPTY")));
      } else {
        callback()
      }
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/general.scss";

#channel-management-detail {
  background-color: $white;
  border-radius: 10px;
  padding-bottom: 2rem;
  padding-top: 2rem;
  .status-row {
    >span {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  .el-form {
    margin: auto $spacingLeftRight;
  }
}
</style>